.order-detail-svg {
    color: #009342;
    cursor: pointer;
}

.productDetails {
    display: flex;
    justify-content: space-between;

    .productDetail {
        margin-left: 20px;
        margin-bottom: 20px;
        padding: 20px;
        background-color: #f1f5f9;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pdleft h6 {
            font-weight: 900
        }

        .pdleft p span {
            color: #009342;
        }

        .pdright img {
            width: 78px;
            border-radius: 10px;
        }
    }

    .Orderdetails {
        width: 100%;

        h6 {
            font-weight: 900
        }

        .odcscr {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .orderdetail {
                width: 70%;

                p {
                    margin-left: 10px;
                    font-weight: 500;
                    font-size: 13px;

                    span {
                        color: #009342;
                    }
                }
            }
        }
    }
}

.product-OandL-detail p {
    background-color: #f1f5f9;
    background-size: 18px;
    padding-left: 1.7rem;
    font-weight: 600;
    opacity: 0.9;
    border-radius: 10px;

    span {
        font-weight: 400;
    }
}

.react-bootstrap-table th .order>.dropdown>.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.react-bootstrap-table th .order>.dropup>.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-bottom: 4px dashed;
    border-bottom: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.auth .brand-logo {
    display: flex;
    justify-content: center;
}

.login-title {
    font-size: 24px;
    font-weight: 100;
    color: #009342;
    display: flex;
    justify-content: center
}

ul.pagination {
    list-style: none;
    display: flex;
}

.error-message {
    color: red;
}

.col-md-4.p0 {
    padding-left: 0 !important;
    margin-bottom: 20px
}
.col-md-6.p0 {
    padding-left: 0 !important;
    margin-bottom: 20px
}

.col-md-6.top-column {
    margin-bottom: 20px
}

.pagination-section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media(max-width : 540px) {
        display: block;
    }
}
.table-head{
    align-items: center;
    display: flex;
    justify-content: space-between;

}