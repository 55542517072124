.react-bootstrap-table table.table .btn {
  padding: 0.4rem 1rem !important;
}

.dropdown {
  overflow: visible !important;
}

.dropdown-menu.show {
  display: block;
}

.show.react-bs-table-sizePerPage-dropdown ul:last-child {
  display: block;
  top: -190px;
}

.react-bs-table-pagination .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
  top: -190px;
}

.pagination .btn-primary {
  color: #fff !important;
  background-color: #009342 !important;
  border-color: #009342 !important;
}

.pointer {
  cursor: pointer;
}

.react-bootstrap-table table.table .btn.table-button,
.table-button {
  padding: 6px !important;
}

.modal-map {
  height: 90%;
}

.d-none {
  display: none !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.max-width-15 {
  max-width: 15%;
}

.hide {
  display: none !important;
}

/*************** 1.Variables ***************/

/* ------------------ Color Pallet ------------------ */

/*************** 2.Mixins ***************/

/************************************************
    ************************************************
										Search Box
	************************************************
************************************************/

.chat-search-box {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
}

.chat-search-box .input-group .form-control {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  border-right: 0;
}

.chat-search-box .input-group .form-control:focus {
  border-right: 0;
}

.chat-search-box .input-group .input-group-btn .btn {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  margin: 0;
  height: 100%;
}

.chat-search-box .input-group .input-group-btn .btn i {
  font-size: 1.2rem;
  line-height: 100%;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .chat-search-box {
    display: block;
  }
}

/************************************************
	************************************************
									Users Container
	************************************************
************************************************/

.users-container {
  position: relative;
  padding: 1rem 0;
  border-right: 1px solid #e6ecf3;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 500px;
  overflow: scroll;
}

/************************************************
	************************************************
											Users
	************************************************
************************************************/

.users {
  padding: 0;
}

.users .person {
  position: relative;
  width: 100%;
  padding: 10px 1rem;
  cursor: pointer;
  border-bottom: 1px solid #f0f4f8;
}

.users .person:hover {
  background-color: #ffffff;
  /* Fallback Color */
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#e9eff5),
      to(#ffffff));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(to-left, #e9eff5, #ffffff);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(to-left, #e9eff5, #ffffff);
  /* FF3.6 */
  background-image: -ms-linear-gradient(to-left, #e9eff5, #ffffff);
  /* IE10 */
  background-image: -o-linear-gradient(to-left, #e9eff5, #ffffff);
  /* Opera 11.10+ */
  background-image: linear-gradient(to-left, #e9eff5, #ffffff);
}

.users .person.active-user {
  background-color: #ffffff;
  /* Fallback Color */
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#f7f9fb),
      to(#ffffff));
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(to-left, #f7f9fb, #ffffff);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(to-left, #f7f9fb, #ffffff);
  /* FF3.6 */
  background-image: -ms-linear-gradient(to-left, #f7f9fb, #ffffff);
  /* IE10 */
  background-image: -o-linear-gradient(to-left, #f7f9fb, #ffffff);
  /* Opera 11.10+ */
  background-image: linear-gradient(to-left, #f7f9fb, #ffffff);
}

.users .person:last-child {
  border-bottom: 0;
}

.users .person .user {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.users .person .user img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.users .person .user .status {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background: #e6ecf3;
  position: absolute;
  top: 0;
  right: 0;
}

.users .person .user .status.online {
  background: #9ec94a;
}

.users .person .user .status.offline {
  background: #c4d2e2;
}

.users .person .user .status.away {
  background: #f9be52;
}

.users .person .user .status.busy {
  background: #fd7274;
}

.users .person span.name-time {
  font-weight: 600;
  font-size: 0.85rem;
  display: inline-block;
  min-width: 78%;
}

.users .person span.name-time .time {
  font-weight: 400;
  font-size: 0.7rem;
  text-align: right;
  color: #8796af;
}
.my-chat-title{
  font-size: 20px;
}
.select-chat-title{
  font-size: 20px;
}
.user-list-container{
  width: 30%
}
.chat-list-container{
  width:70%
}

@media (max-width: 767px) {
  .users .person .user img {
    width: 30px;
    height: 30px;
  }

  .my-chat-title{
    font-size: 15px;
  }
  .select-chat-title{
    font-size: 15px;
  }
  .add-user-button{
    font-size: 10px !important;
  }

  .users .person span.name-time {
  font-size: 10px;
  font-weight: 500;    
  }

  .users .person span.name-time .time {
    display: none;
  } 
}

/************************************************
	************************************************
									Chat right side
	************************************************
************************************************/

.selected-user {
  width: 100%;
  padding: 0 15px;
  min-height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #e6ecf3;
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.selected-user span {
  line-height: 100%;
}

.selected-user span.name {
  font-weight: 700;
}

ul.chat-box {
  padding: 10px;
}

.chat-container {
  list-style-type: none;
  padding: 0;
  height: 435px;
}

.chat-container li.chat-left,
.chat-container li.chat-right {
  display: flex;
  margin-bottom: 10px;
  padding: 5px;
}

.chat-container li.chat-left {
  justify-content: flex-start;
}

.chat-container li.chat-right {
  justify-content: flex-end;
}

.chat-container li .chat-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-container li.chat-right>.chat-avatar {
  margin-left: 20px;
  margin-right: 0;
}

.chat-container li .chat-name {
  font-size: 0.75rem;
  color: #999999;
  text-align: center;
  margin-bottom: 5px;
}

.chat-container li .chat-text {
  padding: 6px 15px;
  background-color: #ffffff;
  /* For received messages */
  font-weight: 400;
  line-height: 1.5;
  position: relative;
  max-width: 60%;
  word-wrap: break-word;
  border-radius: 18px;
  border-top-left-radius: 0px;
  /* Left-side curve removal for received */
}


.chat-container li.chat-right>.chat-text {
  padding: 6px 15px;
  /* text-align: right; */
  background: #25d366;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
  max-width: 60%;
  word-wrap: break-word;
  border-radius: 18px;
  border-top-right-radius: 0px;
  /* Left-side curve removal for received */
}


.chat-container li .chat-hour {
  padding: 0;
  margin-top: 5px;
  font-size: 0.75rem;
  color: #999999;
  text-align: right;
}

.chat-container li.chat-right .chat-hour {
  color: #ffffff;
}


.name-time .name {
  height: 0px;
}

.chat-container li .chat-hour>span {
  font-size: 16px;
  color: #9ec94a;
}

.chat-container li.chat-right>.chat-hour {
  margin: 0 15px 0 0;
}

.chatContainerScroll {
  max-height: 435px;
  overflow: scroll;
  height: 435px
}

.select-text {
  display: flex;
  justify-content: center;
}

.loading-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh
}

.loading-message .spinner-border {
  border: 0.3rem solid #08a74f;
  border-right-color: rgb(0, 198, 88.8979591837);
  border-width: 4px;
}

.display-arrow{
  display: none;
}

@media (max-width: 767px) {

  .chat-container li.chat-left,
  .chat-container li.chat-right {
    /* flex-direction: column; */
    margin-bottom: 30px;
  }

  .chat-container li img {
    width: 32px;
    height: 32px;
  }

  .chat-container li.chat-left .chat-avatar {
    margin: 0 0 5px 0;
    display: flex;
    align-items: center;
  }

  .chat-container li.chat-left .chat-hour {
    justify-content: flex-end;
  }

  .chat-container li.chat-left .chat-name {
    margin-left: 5px;
  }

  .chat-container li.chat-right .chat-avatar {
    order: -1;
    margin: 0 0 5px 0;
    align-items: center;
    display: flex;
    justify-content: right;
    flex-direction: row-reverse;
  }

  .chat-container li.chat-right .chat-hour {
    justify-content: flex-start;
    order: 2;
  }

  .chat-container li.chat-right .chat-name {
    margin-right: 5px;
  }

  .chat-container li .chat-text {
    font-size: 0.8rem;
  }
}
@media (max-width: 500px) {
  .user-list-container{
    width:100%
  }
  .chat-list-container{
    width:100%
  }
  .display-selected-chat{
    display: none;
  }
  .hide-chat-container{
    display: none;
  }
  .display-arrow{
    display: block;
  }
}
.chat-form {
  padding: 15px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card.card-contacts {
  border: 0;
  background: #f4f5fb;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 2rem;
  box-shadow: none;
}

.red-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.message-time {
  font-size: 12px;
  color: gray;
}

.flipping-circle {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  color: white;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  /* Adjust size for better visibility */
  animation: flip 1s infinite;
  /* Add animation */
}

.flipping-circle::after {
  content: attr(data-count);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Keyframes for flipping animation */
@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}



.dot-with-count {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  color: white;
  text-align: center;
  line-height: 25px;
}

.dot-with-count::after {
  content: attr(data-count);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.green-dot-with-count {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: green;
  color: white;
  text-align: center;
  line-height: 25px;
}

.green-dot-with-count::after {
  content: attr(data-count);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-edit .tab-content {
  width: 100%;
}

.example-custom-input {
  background: url("/calendar.svg") no-repeat right;
  background-size: 20px;
  height: 24px;
}

.example-custom-input:focus-visible {
  border-bottom-color: #009342;
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #009342;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  border: 1px solid #009342;
  margin: 0.066rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #009342;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #009342;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.producer-filter {
  width: 24%;
}

.custom-date-picker {
  height: 37px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.height {
  height: 35px;
}

.productDetail-list .pdleft {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quantity-input .btn {
  min-width: 40px;
}

.quantity-input {
  display: flex;
  margin-top: 6px;
}

.quantity-input .form-control {
  width: 60px !important;
}

.invoice-image {
  display: flex;
  margin-left: 4px;
}

.invoice-image img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  margin-left: 4px;
}

.file-item iframe {
  border: 1px solid #ddd;
  border-radius: 50%;
}

.iframe-main {
  cursor: pointer;
  position: relative;
  margin-left: 4px;
  border-radius: 50%;
}

.iframe-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.tab-label {
  font-size: 1rem;
  font-weight: 600;
  color: #009342;
}

.nav-tabs {
  border-color: #009342;
}

.nav-tabs .nav-link.active {
  border-color: #009342;
  border-bottom-color: white;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #009342;
  border-bottom-color: white;
}

.flex-item {
  flex: 0 0 48%;
  margin-bottom: 1rem;
}

table {
  table-layout: fixed;
  width: 100%;
}

table th,
table td {
  padding: 10px;
  width: auto;
  text-align: left;
}


.dragging-helper {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  display: table;
  border-collapse: collapse;
}

.dragging-helper td {
  padding: 10px;
  width: auto !important;
}

.dragging-helper img {
  width: 50px !important;
  height: auto !important;
}

table {
  table-layout: fixed;
  width: 100%;
}
.filter-item {
  flex: 1; 
  min-width: 200px; 
}

.custom-date-picker {
  width: 100%; 
}
.filter-item .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
  /* margin-top: -109px; */
  margin-bottom: 20px;
}
.spinner-container {
  min-height: 300px;
  position: relative;
}
.spinner-container .spinner-border{
  position: absolute;
  top: 37%;
}
.export-button{
display: flex;
justify-content: flex-end;
margin-bottom: 17px;
}
.add-po-datepicker .react-datepicker-wrapper{
  width:100%
}
.custom-date-picker:focus {
  border-color: #757575; 
  outline: none;
}

.notification-button{
  display: flex;
}

.filter-input{
  display: flex;
}

.search-filter{
  width:200px
}
.select-producer-filter{
  width: 200px;
  margin-left: 10px;
}
@media (max-width: 444px) {
  .notification-button{
    overflow: scroll;
  }
  .filter-input{
    display: block;
  }
  .search-filter{
    width:100%
  }
  .select-producer-filter{
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}
.custom-modal .modal-header {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.custom-modal h5 {
  border-left: 4px solid #009342;
  padding-left: 10px;
}

.table-secondary {
  background-color: #f8f9fa !important;
}

.font-weight-bold td {
  font-weight: 600 !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table th, 
.react-bootstrap-table td {
  white-space: nowrap;
  vertical-align: middle !important;
}

